.App {
  text-align: center;
}

* {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.heading {
  background-color: #052c03;
}

.header-link {
  text-decoration: none;
  color: white;
}

.small-logo {
  max-width: 2%;
  min-width: 2rem;
  height: auto;
}

.footer-logo {
  max-width: 2%;
  min-width: 2rem;
  height: auto;
}

.nav-button {
  transition: 0.2s;
}

.nav-button:hover {
  background-color: rgb(66, 148, 74);
}

.header-link:hover {
  font-weight: bold;
}

.home-header {
  background-color: rgba(3, 75, 15, 0.766);
}

.resume-viewer {
  width: 80%;
}

.singular-image {
  max-width: 80%;
  min-width: 60%;
  height: auto;
}

.singular-image-work-term {
  max-width: 95%;
  min-width: 40%;
  height: auto;
}

.work-term-report-text {
  max-width: 95%;
}

.dual-images {
  max-width: 35%;
  height: auto;
}

.small-image {
  width: 15%;
}

.figure-caption {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}